<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createReferralCompany"
        v-if="hasPermission('create_company')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <!-- Filters Below  -->
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-form-input id="name" v-model="name" placeholder="Name" />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- Filters above  -->
      <b-table
        responsive="sm"
        :fields="fields"
        :items="referralCompanys"
        details-td-class="m-0 p-0"
        small
        :per-page="perPage"
      >
        <template #cell(serial)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>

        <template #cell(review)="row">
          {{ truncate(row.item.review, 30) }}
        </template>

        <template #cell(rating)="row"> {{ row.item.rating }} stars </template>

        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{ row.item.created_by }}
        </template>
        <template #cell(updated_by_data)="row">
          {{ row.item.updated_by }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editReferralCompany(row.item)"
            v-if="hasPermission('update_company')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeReferralCompany(row.item)"
            v-if="hasPermission('delete_company')"
          >
            Delete
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
    </b-card>
    <ReferralCompanyCreateModal
      @modalClosed="onModalClosed"
      :key="`create-${referralCompanyCreateModalCount}`"
    />
    <ReferralCompanyEditModal
      :referralCompany="referralCompany"
      @modalClosed="onModalClosed"
      :key="`edit-${referralCompanyEditModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import ReferralCompanyEditModal from "@/components/admin/referralCompany/ReferralCompanyEditModal.vue";
import ReferralCompanyCreateModal from "@/components/admin/referralCompany/ReferralCompanyCreateModal.vue";

export default {
  components: {
    ReferralCompanyCreateModal,
    ReferralCompanyEditModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "NAME" },
        { key: "company_name", label: "COMPANY NAME" },
        { key: "referral_code", label: "REFERRAL CODE" },
        { key: "created_at", label: "CREATED AT" },
        { key: "created_by_data", label: "CREATED BY" },
        { key: "updated_at", label: "UPDATED AT" },
        { key: "updated_by_data", label: "UPDATED BY" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      referralCompany: null,
      referralCompanys: [],
      faqCategories: [],
      referralCompanyCreateModalCount: 0,
      referralCompanyEditModalCount: 0,
      searchTypes: [{ value: 1, name: "Name" }],
      searchType: null,
      name: "",
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
  methods: {
    ...mapActions({
      getRefferalCompany: "appData/getRefferalCompany",
      deleteRefferalCompany: "appData/deleteRefferalCompany",
    }),
    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            break;
        }
      } else {
        this.name = "";
      }
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },

    async fetchPaginatedData() {
      try {
        const res = await this.getRefferalCompany({
          pageNumber: this.currentPage,
          name: this.name ? this.name : "",
        });
        this.referralCompanys = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createReferralCompany() {
      this.referralCompanyCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("referralCompany-create-modal");
      });
    },
    editReferralCompany(referralCompany) {
      this.referralCompany = referralCompany;
      this.referralCompanyEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("refferalCompany-edit-modal");
      });
    },
    async removeReferralCompany(referralCompany) {
      this.referralCompany = referralCompany;
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteRefferalCompany({
              pk: this.referralCompany.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "Referral Company deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            console.error("Error deleting Referral Company:", error);
          }
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
